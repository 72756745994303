import { QUERY_KEYS } from '@/config/QueryKeys';
import { getAsset } from '@badgermoleV2/basic';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const useAssetInfo = (vin: string | undefined) => {
  const [live, setLive] = useState(true);
  const { i18n } = useTranslation();

  const { data, isFetching, error, dataUpdatedAt, refetch } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.assetInfo, vin, live],
    queryFn: async ({ signal }) => await getAsset(vin!, live, { signal }),
    staleTime: 1000 * 60 * 5,
    retry: 1,
    enabled: vin !== undefined
  });

  useEffect(() => {
    if (error) {
      const message =
        i18n.language === 'de'
          ? ' Fahrzeugtelematik konnte nicht erreicht werden, wechsle zu cached Fahrzeugdaten'
          : ' Vehicle Telematic could not be reached, switch to cached data';
      toast.error(message, { duration: 5000 });
      setLive(false);
    }
  }, [error, vin, i18n.language]);

  return {
    asset: data,
    refetchAsset: refetch,
    isAssetFetching: isFetching,
    lastUpdatedTime: dataUpdatedAt
  };
};
